<template>
  <el-collapse-item :name="orderDeviceIndex" :class="{ 'el-collapse-item-custom': $store.state.quickStart.step === 3 }">
    <template v-slot:title>
      <quick-start-tip
        title="Información del Dispositivo"
        content="Aqui puedes agregar o crear el dispositivo que vas a reparar.
        Puedes crear un checklist de revision, y tambien puedes agregar la infomacion de su desbloqueo."
        placement="left"
        :bg-purple="false"
        :value="showQuickStartTip"
        @onNextStep="handleNextStep">
        <div class="space-x-4">
          <span class="font-bold text-md px-4">
            {{ device }}
          </span>
        </div>
      </quick-start-tip>
    </template>
    <content-card>
      <template v-slot:title>
        <div>
          <device-model-block
            :orderDevice="orderDevice"
            @onDeviceModelSelected="onDeviceModelSelected"
          />
        </div>
      </template>
      <template v-slot:actions>
        <div class="ml-6">
          <sa-icon-button
            v-if="orderDeviceIndex >= 1"
            iconClass="text-xl bx bx-x"
            tooltip="Cancelar"
            @click="$emit('removeDevice', orderDeviceIndex)"
          />
        </div>
      </template>
      <div
        v-if="orderDevice.deviceModelId"
        class="flex flex-col py-2">
        <div class="w-full flex sm:flex-row lg: flex-col py-4 mb-4 lg:mb-0 space-y-3 lg:space-y-0">
          <div class="sm:w-full lg:w-1/2 lg:mr-2 sm:mb-4">
            <el-radio-group
              class="mb-2"
              :class="{ 'quick-start-selected': $store.state.quickStart.step === 3 }"
              :style="$store.state.quickStart.step === 3 ? '': 'height: 21px;'"
              v-model="orderDevice.serialNumberType">
              <el-radio :label="1">Número de serie</el-radio>
              <el-radio :label="2">IMEI</el-radio>
            </el-radio-group>
            <el-input v-model="orderDevice.imei" 
            :placeholder="!orderDevice.serialNumberType || orderDevice.serialNumberType == 1 ? 'Ingrese en número de serie':'Ingrese el IMEI'" />
          </div>

          <div class="sm:w-full lg:w-1/2">
            <el-radio-group
              class="mb-2"
              :class="{ 'quick-start-selected': $store.state.quickStart.step === 3 }"
              :style="$store.state.quickStart.step === 3 ? '': 'height: 21px;'"
              v-model="orderDevice.deviceLockType">
              <el-radio :label="1">Passcode</el-radio>
              <el-radio :label="2">Patrón</el-radio>
            </el-radio-group>
            <el-input
              v-if="orderDevice.deviceLockType === 1"
              v-model="orderDevice.passcode" />
            <div v-else>
              <sa-button type="secondary" class="w-full" @click="patternVisible = true">
                Establecer patrón
              </sa-button>
            </div>
          </div>
        </div>
        <div class="w-full pb-4 mb-4">
          <el-checkbox
            v-if="false"
            v-model="orderDevice.hasDiagnostic">
          <span>
            Descripción del Problema
          </span>
          </el-checkbox>
          <div
            v-if="orderDevice.hasDiagnostic"
            class="my-2">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="Descripción del Problema"
              v-model="orderDevice.diagnosticNotes">
            </el-input>
          </div>
        </div>

        <div class="-mx-5 border-t border-gray-200 " />

        <div class="pt-4">
          <services-block :orderDeviceIndex="orderDeviceIndex" />
        </div>

        <div class="-mx-5 border-t border-gray-200 " />

        <div class="pt-4">
          <products-block :orderDeviceIndex="orderDeviceIndex" />
        </div>
      </div>
      <el-dialog
        :visible.sync="patternVisible"
        width="300px">
        <div v-if="pattern" class="w-full grid">
          <strong>Patrón</strong>
          <div class="w-full flex flex-row">
            <sa-icon-button
              iconClass="text-sm bx bx-trash"
              @click.stop="deletePattern"
            />
            <el-input class="w-full" v-model="patternSequence" disabled />
          </div>
        </div>
        <svg
          v-if="!pattern"
          class="patternlock"
          id="lock"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg">
          <g class="lock-actives"></g>
          <g class="lock-lines"></g>
          <g class="lock-dots">
            <circle cx="20" cy="20" r="2"/>
            <text x="17" y="15" font-size="8px">1</text>
            <circle cx="50" cy="20" r="2"/>
            <text x="47" y="15" font-size="8px">2</text>
            <circle cx="80" cy="20" r="2"/>
            <text x="77" y="15" font-size="8px">3</text>

            <circle cx="20" cy="50" r="2"/>
            <text x="17" y="45" font-size="8px">4</text>
            <circle cx="50" cy="50" r="2"/>
            <text x="47" y="45" font-size="8px">5</text>
            <circle cx="80" cy="50" r="2"/>
            <text x="77" y="45" font-size="8px">6</text>

            <circle cx="20" cy="80" r="2"/>
            <text x="17" y="75" font-size="8px">7</text>
            <circle cx="50" cy="80" r="2"/>
            <text x="47" y="75" font-size="8px">8</text>
            <circle cx="80" cy="80" r="2"/>
            <text x="77" y="75" font-size="8px">9</text>
          </g>
        </svg>
      </el-dialog>
    </content-card>
  </el-collapse-item>
</template>
<style>
.el-collapse-item-custom > div> .el-collapse-item__header {
  border: 8px solid #8B5CF6;
  border-radius: 2px;
}
.quick-start-selected {
  border: 8px solid #8B5CF6;
  border-radius: 2px;
  padding: 4px;
}
svg.patternlock g.lock-lines line {
  stroke-width: 1.5;
  stroke: black;
  opacity: 0.5;
}

svg.patternlock g.lock-dots circle {
  stroke: transparent;
  fill: black;
  stroke-width: 13.5;
}

svg.patternlock g.lock-actives circle {
  fill: black;
  opacity: .2;
  animation: lock-activate-dot .15s 0s ease 1;
  transform-origin: center;
}

svg.patternlock g.lock-lines line {
  stroke-width: 1.5;
  stroke-linecap: round;
}

svg.patternlock.success g.lock-actives circle {
  fill: green;
}

svg.patternlock.error g.lock-actives circle {
  fill: red;
}

@keyframes lock-activate-dot {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
}
</style>
<script>
import _ from 'lodash';
import PatternLock from 'pattern-lock-js';
import { state, mutations } from '../store';
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';

export default {
  name: 'DevicesBlock',
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard'),
    SaButton: () => import('@/components/atoms/SaButton.vue'),
    ServicesBlock: () => import('../service/ServicesBlock.vue'),
    ProductsBlock: () => import('../product/ProductsBlock.vue'),
    DeviceModelBlock: () => import('../model/DeviceModelBlock.vue'),
  },
  props: {
    orderDeviceIndex: {
      type: Number,
      default: () => null,
    },
    active: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      orderDevice: state.order.orderDevices[this.orderDeviceIndex],
      showAdditionalInfo: false,
      brands: [],
      models: [],
      categories: [],
      passcode: null,
      patternVisible: false,
      pattern: null,
      showQuickStartTip: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.passcode = state.order.orderDevices[this.orderDeviceIndex].passcode;
      this.pattern = state.order.orderDevices[this.orderDeviceIndex].pattern;
    }, 1000);
    setTimeout(() => {
      this.showQuickStartTip = this.$store.state.quickStart.step === 3;
    }, 100);
  },
  watch: {
    'patternVisible': {
      deep: true,
      handler: _.debounce(function() {
        this.patternLock();
      }, 300)
    },
    'pattern': {
      deep: true,
      handler: _.debounce(function() {
        this.patternLock();
      }, 300)
    },
  },
  computed: {
    patternSequence() {
      let pattern = '';
      for (let i = 0; i < this.pattern.length; i++) {
        pattern += this.pattern[i] + '-';
      }
      return pattern.substr(0, pattern.length - 1);
    },
    device() {
      const device = state.order.orderDevices[this.orderDeviceIndex];
      if (device.deviceModel) return `${device.deviceModel.brand} - ${device.deviceModel.model}`;
      return null;
    },
  },
  methods: {
    patternLock() {
      if (this.patternVisible && !this.pattern) {
        new PatternLock("#lock", {
          onPattern: function(pattern) {
            mutations.changeDevicePattern(undefined, pattern);
          }
        });
      }
    },
    onDeviceModelSelected(deviceModelSelected) {
      this.orderDevice.deviceModel = deviceModelSelected;
      this.orderDevice.deviceModelId = deviceModelSelected.id;
      this.orderDevice.deviceType = deviceModelSelected.deviceType;

      this.orderDevice.checklistItems = deviceModelSelected.checklistItems;
      this.orderDevice.deviceTypeId = deviceModelSelected.deviceTypeId;
    },
    deletePattern() {
      this.pattern = null;
      mutations.changeDevicePattern(undefined, this.pattern);
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
      if (step === 3) {
        this.showQuickStartTip = false;
      }
    },
  }
}
</script>
